import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../../fetch/generalFailedPC';
import Tooltip from '../../../general/Tooltip';
import FormModal from '../../../fetch/FormModal';
import generalNoParameterFormPC from '../../../fetch/generalNoParameterFormPC';
import {CartLine} from '../../../../constants/propTypesDefinitions';
import {createFetchCartRemoveItems} from '../../../../backend/apiCalls';
import {Icon, Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class RemoveCartItem extends React.PureComponent {
    static propTypes = {
        reload: PropTypes.func.isRequired,
        cartLine: CartLine.isRequired,
    };

    render() {
        const {cartLine, reload} = this.props;
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Odstránenie položky</Trans>}>
                    <Button size="small" type="danger" icon="delete"/>
                </Tooltip>
            }
            values={{}}
            onFinish={reload}
            title={<Trans>Odstránenie položky z košíka</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Chcete odstrániť položku z košíka?</Trans>,
                createFetchCartRemoveItems(cartLine.id_customer, cartLine.id_item),
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa odstrániť položku z košíka.`)}
        />;
    }

}

export default RemoveCartItem;