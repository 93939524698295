import React from 'react';
import PropTypes from 'prop-types';
import {Button, Icon, notification, Upload} from 'antd';
import {Trans, t} from '@lingui/macro';
import DisplayModal from '../../general/DisplayModal';
import Tooltip from '../../general/Tooltip';
import { numberOrNull } from '../../../lib/number';
import InputTextArea from '../../general/InputTextArea';
import { GLOBAL_DATA } from '../../../constants/globalData';
import withDataHOC from '../../dataProvider/withDataHOC';
import { createFetchCartAddItems, createFetchProductIndex } from '../../../backend/apiCalls';
import ProductEditableRQO from '../../project/ProductEditableRQO';
import InputQuantity from '../../project/InputQuantity';
import update from 'immutability-helper';
import { formatQuantityAvailable } from '../../../lib/formatting';

/**
 * @dusan
 */

class CartImport extends React.PureComponent {
    static propTypes = {
        customerId: PropTypes.number,
        reload: PropTypes.func.isRequired,
        [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            raw: null,
            items: [],
            itemsToLoad: [],
            loading: false
        }
    }
    
    componentDidMount() {
        this.onClear();
    }

    componentDidUpdate(prevProps, prevState) {
        const {itemsToLoad} = this.state;
        const {itemsToLoad: prevItemsToLoad} = prevState;
        if(itemsToLoad.length > 0 && itemsToLoad.length != prevItemsToLoad.length)
        {
            // change of items to load, load next, do not load in paralell (self DoS :)
            const [item, ...nextItems] = itemsToLoad;
            this.getItem(item, (it) => {
                this.setState((prevState) => {
                    const {items} = prevState;
                    const newItems = [...items, it];
                    return {
                        items: newItems,
                        itemsToLoad: nextItems
                    };
                });
            });
        }
    } 

    onInput = (str) => {
        this.setState({raw: str});
    }

    startParse = () => {
        const {raw} = this.state;
        const lines = raw != null ? raw.split(/[\r\n]+/).filter(ln => ln.trim()) : [];
        this.setState({items: [], itemsToLoad: lines});
    }

    onItemUpdate = (updateObj) => {
        const {items} = this.state;
        const newItems = update(items, updateObj);
        this.setState({items: newItems});
    }

    onClear = () => {
        this.setState({items: [], raw: null, itemsToLoad: [], loading: false});
    }

    getItem = (str, onFinish) => {
        const {[GLOBAL_DATA.FETCH_HANDLER]: fetchHandler, customerId} = this.props;
        // separate quantity (last) from the search phrase

        const line = str != null ? str.trim() : "";
        if(line.length == 0)
        {
            // nothing
            onFinish(null);
            return;
        }

        let quantity = null;
        let phrase = null;
        for(let i=line.length-1; i > 0; i--)
        {
            const q = Number(line.substring(i).replace(',', '.'));
            if(Number.isNaN(q))
            {
                if(quantity != null)
                    phrase = line.substring(0, i+1).trim();
                else
                    phrase = line;
                break;
            }
            else
            {
                quantity = q;
            }
        }
        
        if(phrase == null || phrase == "")
        {
            // e.g. 6205 bearing without quantity
            phrase = line;
            quantity = null;
        }

        // search for the product
        fetchHandler(
            createFetchProductIndex(),
            {
                designation: phrase,
                id_customer: customerId,
                search_type: 'like',
                order: ['quantity_available desc', 'relevance asc'],
                limit: 1
            },
            (products) => {
                if(products.length > 0)
                {
                    // success
                    const p = products[0];
                    
                    // compare if all words from phrase are contained in designation and manufacturer
                    const regex = /[\u0300-\u036f\s\-\+\(\)\\\,\.\*]+/ig;
                
                    const foundWords = (p.designation + ' ' + p.manufacturer)
                        .normalize("NFD")
                        .replace(regex, ' ')
                        .toLowerCase()
                        .split(' ');

                    const phraseWords = phrase
                        .normalize("NFD")
                        .replace(regex, ' ')
                        .toLowerCase()
                        .split(' ');

                    let goodMatch = true;
                    phraseWords.forEach((w) => {
                        if(!foundWords.includes(w))
                            goodMatch = false;
                    });

                    foundWords.forEach((w) => {
                        if(!phraseWords.includes(w))
                            goodMatch = false;
                    });
                    

                    onFinish({
                        id_product: p.id,
                        designation: p.designation,
                        manufacturer: p.manufacturer,
                        quantity_min: p.quantity_min,
                        quantity_div: p.quantity_div,
                        quantity_units: p.quantity_units,
                        quantity_available: p.quantity_available,
                        phrase: phrase,
                        quantity: quantity,
                        goodMatch: goodMatch
                    });
                }
                else
                {
                    // not found
                    onFinish({
                        phrase: phrase,
                        quantity: quantity,
                        error: <Trans>produkt sa nenašiel</Trans>
                    })
                }
            },
            null,
            (error) => {
                notification['error']({ 
                    message: error.message,
                    duration: 10,
                });
            }
        );
    }

    beforeUpload = (file) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            if(e.target != null)
                this.onInput(e.target.result);
        };
        reader.readAsText(file);

        // Prevent upload
        return false;
    }

    onSubmit = () => {
        const {[GLOBAL_DATA.FETCH_HANDLER]: fetchHandler, customerId, reload} = this.props;
        const {items} = this.state;

        if(items.length == 0)
            return;

        this.setState({loading: true});

        fetchHandler(
            createFetchCartAddItems(),
            {id_customer: customerId, items: items},
            () => {
                if(this.modal != null && this.modal.handleClose != null)
                    this.modal.handleClose();

                this.onClear();
            },
            () => {
                this.setState({loading: false});
                if(reload != null) 
                    reload();
            },
            (error) => {
                notification['error']({ 
                    message: error.message,
                    duration: 10,
                });
            }
        );
    }

    render() {
        const {items, itemsToLoad, loading, raw} = this.state;
        const disabled = items.length == 0 
            || items.find((it) => (it.id_product == null || it.quantity == null || it.quantity < 0)) != null;
        return <DisplayModal
            ref={node => this.modal = node}
            openNode={
                <Tooltip 
                    title={<Trans>Importujte .CSV súbor obsahujúci stĺpce (označenie, množstvo)</Trans>}
                >
                    <Button>
                        <Icon type="upload" className="mr-2"/>
                        <Trans>Import</Trans>
                    </Button>
                </Tooltip>
            }
            title={<Trans>Import do košíka</Trans>}
        >
        { items.length > 0 && itemsToLoad.length == 0 ? 
            <div>
                <ImportActions
                    onClear={this.onClear}
                    onSubmit={this.onSubmit}
                    loading={loading}
                    disabled={disabled}
                />
                <ImportedRowsTable
                    items={items}
                    onItemsChange={this.onItemUpdate}
                />
            </div>
            :
            <div>
                <InputTextArea
                    className="full-size-width"
                    placeholder="e.g. 6204-2RS  10"
                    value={raw}
                    changeImmediately={true}
                    disabled={loading || itemsToLoad.length > 0}
                    onChange={this.onInput}
                    autoSize={{
                        minRows: 5,
                        maxRows: 30
                    }}
                />
                <div className="d-flex full-size-width flex-row-reverse justify-content-between align-items-center">
                    <Button
                        type="primary"
                        icon="caret-right"
                        disabled={raw == null || raw == ""}
                        onClick={this.startParse}
                        loading={itemsToLoad.length > 0}
                    >
                        <span className='ml-2'><Trans>Import</Trans></span>
                    </Button>
                    <Upload 
                        accept='.csv'
                        showUploadList={false}
                        beforeUpload={this.beforeUpload}
                    >
                        <Button loading={itemsToLoad.length > 0} icon="upload" className="m-2">
                            <span className='ml-2'><Trans>Upload</Trans></span>
                        </Button>
                    </Upload>
                </div>
            </div>
        }
        </DisplayModal>;
    }

}

export default withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(CartImport);


class ImportedRowsTable extends React.PureComponent {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.object).isRequired,
        onItemsChange: PropTypes.func.isRequired,
    };

    createOnProductIdChange = (key) => (newProductId) => {
        const {onItemsChange} = this.props;
        onItemsChange({[key]: {
            id_product: {$set: newProductId}
        }});
    }

    createOnProductDataChange = (key) => (newProduct) => {
        const {onItemsChange} = this.props;
        onItemsChange({[key]: {
            designation: {$set: newProduct.designation},
            manufacturer: {$set: newProduct.manufacturer},
            quantity_min: {$set: newProduct.quantity_min},
            quantity_units: {$set: newProduct.quantity_units},
            quantity_div: {$set: newProduct.quantity_div},
            quantity_available: {$set: newProduct.quantity_available},
        }});
    }

    createOnQuantityChange = (key) => (newQuantity) => {
        const {onItemsChange} = this.props;
        onItemsChange({[key]: {
            quantity: {$set: newQuantity}
        }});
    }

    createOnRemove = (key) => () => {
        const {onItemsChange} = this.props;
        onItemsChange({ $splice: [[key, 1]] });
    }

    render() {
        const {items} = this.props;

        return <table className="simple-table full-size-width">
            {items.map((it, key) => {
                const quantityChecked = numberOrNull(it.quantity);
                const availChecked = numberOrNull(it.quantity_available);
                const notCovered = availChecked == null || 
                    (quantityChecked != null ? (availChecked < quantityChecked) : (availChecked == 0));

                return <tr key={key}>
                    <td className="cart-import-designation">
                        <ProductEditableRQO
                            productId={it.id_product}
                            productDesignation={it.phrase}
                            productManufacturer={it.manufacturer}
                            onProductIdChange={this.createOnProductIdChange(key)}
                            onProductDataChange={this.createOnProductDataChange(key)}
                            className={it.goodMatch ? "text-dark" : "color-red"}
                        />
                        <div className={"px-3 table-subdata" + (it.id_product == null ? " color-red" : "")}>
                            <Trans>fráza:</Trans>
                            {' '}
                            {it.phrase}
                        </div>
                    </td>
                    <td className="cart-import-quantity">
                        <InputQuantity
                            className={"text-right" + (quantityChecked == null || quantityChecked <= 0 ? " has-error" : "")}
                            size="small"
                            value={quantityChecked}
                            unit={it.quantity_units}
                            onChange={this.createOnQuantityChange(key)}
                        />
                        { it.quantity_available != null ? 
                            <div className={"table-subdata text-right" + (notCovered ? " color-red" : "")}>
                                <Trans>dostupnosť:</Trans>
                                {' '}
                                {formatQuantityAvailable(it.quantity_available, it.quantity_units)}
                            </div>
                            : null
                        }
                    </td>
                    <td className="cart-import-actions">
                        <Button type="danger" icon="delete" size="small" onClick={this.createOnRemove(key)}/>
                    </td>
                </tr>
            })}
        </table>;
    }

}

class ImportActions extends React.PureComponent {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        onClear: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        loading: PropTypes.bool,
    };

    render() {
        const {onSubmit, onClear, loading, disabled} = this.props;
        return <div className="pb-3 d-flex align-items-center justify-content-between">
            <Button type="danger" icon="trash" onClick={onClear}>
                <Trans>Vyčistiť</Trans>
            </Button>
            <Button type="primary" onClick={onSubmit} loading={loading} disabled={disabled}>
                <Trans>Vložiť do košíka</Trans>
                <Icon type="double-right" className="ml-2"/>
            </Button>
        </div>;
    }

}